.user-feed-seg {
  max-height: 400px;
  overflow: scroll;
}

.user-pg-header {
  font-size: 24px;
  color: var(--color-main);
  font-weight: 600;
  margin: 10px 0px;
}

.bon-reb-cell {
  padding: 0;
  text-align: center !important;
  section {
    width: 50%;
    display: inline-block;
  }
}

// .user-game-cell {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
