:root {
  --color-main: #e4a166; // midnight blue
  --color-sec: #CAB067;
  --color-lg: #6638f0;
  --color-nba: #D06C35;
  --color-mlb: #042F70;
  --color-lg-dark: #411ab5;
}

$phone-hor: 640px;
$tablet: 768px;
$desktop: 1024px;
//
// $nba-color: #D06C35;
// $mlb-color: #042F70;
// $lg-color: #6638f0;

@mixin phone-vert {
  @media (max-width: #{$phone-hor - 1px}) {
    @content;
  }
}

@mixin phone-hor {
  @media only screen
    and (min-device-width : 375px)
    and (max-device-width : 812px)
    and (-webkit-device-pixel-ratio : 3)
    and (orientation : landscape) {
      @content;
    }
}

@mixin tablet {
  @media (min-width: #{$tablet}) and (max-width: #{$desktop - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop}) {
    @content;
  }
}
