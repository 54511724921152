.perf-bonus-sec {
  width: 60%;
  margin: 80px 0 80px 25%;
}

tr.bonus-row {
  line-height: 3.5;
  td {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.ui.toggle.checkbox.nba-bonus-toggle input:checked~label:before {
  background-color: var(--color-main) !important;
}

table.perf-bonus a {
  color: var(--color-main) !important;
}

div.ui.fluid.multiple.selection.dropdown.perf-bonus-sel {
  margin-top: 5px;
  border: 1px solid var(--color-main);
}
