@import "./variables.scss";

.bonus-btn {
  margin-left: 15px !important;
}

.confirm-header {
  background-color: var(--color-main) !important;
  color: white !important;
  padding-bottom: 5px !important;
  padding-top: 1px !important;
  width: 100%;
  .modal-header-bar {
    font-size: 12px;
    color: #f7f7f7;
    font-style: italic;
  }
}

.modal-meta {
  font-size: 12px !important;
  color: #969696;
  padding-top: 0;
}

.modal-header-meta {
  font-size: 14px !important;
  color: white;
  padding-top: 0;
  font-weight: 300;
  text-transform: uppercase;
  font-style: italic;
}

.header-meta {
  display: inline-block;
  margin: 0 10px 0 10px;
}

body > div.ui.page.modals.dimmer.transition.visible.active > div > h2 > div > i {
  font-size: 14px !important;
  display: inline !important;
}

.game-reg-modal .content {
  padding-top: 10px !important;
}

.game-reg-modal h3 {
  margin-bottom: 0;
  margin-top: 0;
}

.game-reg-modal p {
  margin-top: 10px;
}

.modal-check {
  text-align: right;
}
