@import "./variables.scss";

.picks-hidden {
  text-align: center;
  color: var(--color-main);
  font-size: 24px;
  font-weight: 600;
  margin-top: 30px;
}

.scoreboard-pick {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}

.scoreboard-pick img {
  width: 50px !important;
  max-width: 50px;
  max-height: 50px;
  margin: auto;
}

.no-pick {
  margin-top: 7%;
  text-align: center;
}

.schedGridTeam.two-games {
  margin: 0;
}

.schedGridTeam.mlb_cell:not(.two-games) {
  padding: 5px !important;
}

.mlb_cell img {
  // max-width: 50px !important;
  height: 40px !important;
  padding-left: 10px;
  padding-top: -10px;
  padding-bottom: 5px;
}

.mlb_cell_home img {
  // max-width: 50px !important;
  height: 50px !important;
  padding-left: 10px;
  padding-top: -10px;
  padding-bottom: 5px;
}

.schedGridTeam {
  text-align: center !important;
  padding: 0 !important;
  font-weight: 600;
  span {
    display: inline;
  }
  img {
    width: 45px !important;
    display: inline-block !important;
  }
}

.schedGridTeam.tied, .game-one.tied, .game-two.tied {
  background-color: #969696 !important;
}

.schedGridTeam.PPD, .game-one.PPD, .game-two.PPD {
  background-color: #4e4b59 !important;
  div.sched-game-status {
    color: white;
  }
}

.schedGridTeam.losing, .game-one.losing, .game-two.losing {
  background-color: #F0B9BE !important;
}

.schedGridTeam.loss, .game-one.loss, .game-two.loss {
  background-color: #EB4249 !important;
}

.schedGridTeam.win, .game-one.win, .game-two.win {
  background-color: #65B67B !important;
}

.schedGridTeam.winning, .game-one.winning, .game-two.winning {
  background-color: #B5ECBC !important;
}

.winning {
  background-color: #B5ECBC !important;
}

.losing {
  background-color: #F0B9BE !important;
}

.shot-moon {
  background-color: #ca9e24 !important;
}

.sched-game-status {
  margin-top: -6px;
}

.selectionsTable thead, .ui.table:not(.unstackable) thead {
  display: table-header-group !important;
}

.selectionsTable tr, .ui.table:not(.unstackable) tr {
  display: table-row !important;
}

.ui.table:not(.unstackable) tbody {
  display: table-row-group !important;
}

.ui.table:not(.unstackable) tr>td:not(.lives-adj-cell), .ui.table:not(.unstackable) tr>th {
  display: table-cell !important;
  border-left: 1px solid rgba(34, 36, 38, 0.1) !important;
}

.selectionsTable.ui.table td.lives-cell {
  @media screen and (max-width: 776px) {
    background-attachment:scroll !important;
    background-clip:border-box !important;
    background-color:rgba(0, 0, 0, 0) !important;
    background-image:none !important;
    background-origin:padding-box !important;
    background-position-x:0px !important;
    background-position-y:0px !important;
    background-size:auto !important;
    border-bottom-color:rgba(0, 0, 0, 0.87) !important;
    border-bottom-style:none !important;
    border-bottom-width:0px !important;
    border-collapse:separate !important;
    border-image-outset:0px !important;
    border-image-repeat:stretch !important;
    border-image-slice:100% !important;
    border-image-source:none !important;
    border-image-width:1 !important;
    border-left-color:rgba(34, 36, 38, 0.1) !important;
    border-left-style:solid !important;
    border-left-width:1px !important;
    border-right-color:rgba(0, 0, 0, 0.87) !important;
    border-right-style:none !important;
    border-right-width:0px !important;
    border-top-color:rgba(0, 0, 0, 0.87) !important;
    border-top-style:none !important;
    border-top-width:0px !important;
    box-shadow:none !important;
    box-sizing:border-box !important;
    color:rgba(0, 0, 0, 0.87) !important;
    display:table-cell !important;
    font-family:Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
    font-size:14px !important;
    height:70px !important;
    line-height:19.999px !important;
    padding-bottom:11px !important;
    padding-left:11px !important;
    padding-right:11px !important;
    padding-top:11px !important;
    text-align:left !important;
    text-size-adjust:100% !important;
    transition-delay:0s, 0s !important;
    transition-duration:0.1s, 0.1s !important;
    transition-property:background, color !important;
    transition-timing-function:ease, ease !important;
    vertical-align:middle !important;
    width:85px !important;
    -webkit-border-horizontal-spacing:0px !important;
    -webkit-border-vertical-spacing:0px !important;
  }
}

.selectionsTable > thead > tr > th:nth-child(4) {
  width: 6% !important;
  text-align: center;
}

.selectionsTable > tbody > tr > td:nth-child(4) {
  text-align: center;
}

img.popup-logo {
  max-width: 40px !important;
  display: inline !important;
  margin-bottom: -10;
  max-height: 40px;
}

.player-bonus-img {
  max-height: 40px;
}

.bonus-box-team {
  display: flex !important;
  align-items: center;
  justify-content: center;
  img {
    margin-right: 5px;
  }
}

.bonus-box-scores-col {
  font-size: 32px;
  font-weight: 600;
  padding-top: 10;
  background-color: '#F9FAFB';
  vertical-align: 'center';
}

body > div.ui.popup.transition.visible.bonus-box > div > div > div > div,
body > div.ui.popup.transition.visible.bonus-box.bonus-box-mlb > div > div > div > div > div > div {
  padding: 1px !important;
}

.bonus-box-left {
  padding: 0 !important;
  max-height: 310px;
  overflow: scroll;
}

.bonus-box-right {
  overflow: hidden;
  min-height: 310px;
  padding: 0 !important;
  background: url("../../images/crowd_15.jpg") no-repeat center / cover;
}

.bonus-box-mlb {
  width: 750px !important;
  min-height: 310px;
}

.bonus-box-head {
  font-family: 'Luckiest Guy', cursive;
  font-size: 20px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 5px !important;
  background-color: '#EFF2F4';
}

.bonus-box-subhead {
  // font-family: 'Luckiest Guy', cursive;
  font-size: 12px;
  font-weight: 400;
  padding-left: 3px;
  display: flex !important;
  align-items: center !important;
  background-color: '#EFF2F4'
  // margin-top: 5px !important;
}

.bonus-box-head-low {
  font-size: 20px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 2px !important;
  background-color: '#EFF2F4';
}

.bonus-box-cat {
  font-weight: 600;
  background-color: '#F9FAFB';
  text-align: center;
  vertical-align: middle !important;
}

.scoring-plays-header {
  border-top: solid 1px black;
  border-bottom: solid 1px black;
}

.scoring-play {
  border-top: solid 1px #cdcdcd;
  border-bottom: solid 1px #cdcdcd;
  font-size: 12px;
}

.result-text a {
  color: white;
}

.result-text a:hover {
  color: #4FBAFC;
}

.sched-bonus-label {
  position: static !important;
  display: inline-flex !important;
  justify-content: center !important;
  text-align: center;
}

.sched-bonus-label.team-score-left {
  position: absolute !important;
  left: -7px;
  top: 5px;
}

.sched-bonus-label.team-score-right {
  position: absolute !important;
  right: -7px;
  top: 5px;
}

i.bonus-icon {
  margin: 0 !important;
}

.hover-exp {
  text-align: center;
  color: var(--color-main);
  font-size: 14px;
  font-style: italic;
  margin-bottom: -5px;
}
