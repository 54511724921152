@import "./variables.scss";

.scheduleTable {
  tr {
    height: 60px;
  }
  td {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.selectionsTable {
  tr {
    line-height: 20px !important;
  }
  td {
    padding: 10px;
  }
}

.two-game-container {
  // margin-left: -5px;
  display: flex;
  align-items: center;

  .game-one {
    width: 50%;
    border-right: solid 1px #bdbdbd;
    display: inline-block;
  }
  .game-two {
    display: inline-block;
    width: 50%;
  }
}

.scheduleTable > thead > tr > th:nth-child(1) img {
  display: inline !important;
}

.scheduleTable > thead > tr > th:nth-child(1) i {
  display: inline !important;
}

.selectTeam a, .selectedTeam a, .unavailable, .used, .regToPick, .regToPick a, .rebuyToPick, .eliminated {
  color: white !important;
  text-align: center !important;
  font-weight: 600;
}

.selectTeam a:hover, .regToPick:hover {
  background-color: var(--color-main) !important;
  cursor: pointer;
}

.rebuyToPick:hover {
  background-color: #032C5A !important;
}

.eliminated:hover {
  background-color: #CD1131 !important;
}

td.selectedTeam {
  background-color: #34C645;
  padding: 0;
}

.selectedTeam a:after {
  content: 'SELECTED';
}

.selectedTeam a:hover:after {
  background-color: #CC383F;
  content: 'CANCEL';
  padding: 10px 20px;
  cursor: pointer;
}

.unavailable {
  background-color: #969696;
}

.used {
  background-color: #4E4E4E;
}

.past-deadline {
  background-color: white;
  color: white;
}

.past-deadline:hover {
  background-color: #969696;
}

.week-header {
  color: var(--color-main);
  font-weight: 600;
  font-size: 2rem;
  margin-right: 10px;
}

.result-text {
  font-weight: 600;
  font-size: 9px;
  margin-top: -7px;
}

.win {
  background-color: #82C55F;
}

.loss {
  background-color: #C63436;
}

.make-picks-top-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.deadline {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
}

.deadline i {
  margin: 0 10px;
  color: var(--color-main);
}

.picks-locked {
  color: var(--color-main);
  margin-top: 10px;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}

.picks-pagination a.active.item {
  background-color: var(--color-main) !important;
  font-weight: 600 !important;
  color: white !important;
}

.selected-team {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;
}

.click-select {
  background-color: #969696 !important;
  color: white !important;
  font-style: italic;
  text-align: center !important;
}

.confirm-pick-modal {
  position: relative;
}

.modal-logo-img {
  float: right;
  margin-top: -15px !important;
}

.sched-date-head {
  text-align: center !important;
}

img.user-pick-img {
  width: 50px;
  padding: 0;
}

#root > div > div > div.master-container > div > div.ui.bottom.attached.segment.active.tab.nba-content > div > table > thead > tr > th:nth-child(1) {
  display: flex;
    align-items: center;
    padding: 0 10px;
    min-height: 60px !important;
    justify-content: center;
}
