@import "./variables.scss";

.register-form {
  width: 60%;
  margin-left: 20%;
  margin-top: 30px;
}

.reset-pw-form {
  width: 60%;
  margin-left: 20%;
  margin-top: 30px;
}

.reset-pw-summary {
  text-align: center;
  margin: 20px;
}

.reg-header {
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  color: var(--color-main);
  margin-bottom: 10px;

  @media screen and (max-width: 650px) {
    line-height: 36px;
  }
}

.reg-sub-header {
  text-align: center;
  margin-top: 7px;
  margin-bottom: 15px;
  font-size: 14px;
  font-style: italic;
}

.reg-error-header {
  text-align: center;
  // margin-top: 7px;
  // margin-bottom: 15px;
  color: #C63436;
  font-weight: 600;
}

.pw-reset-success {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 3px;
  color: #82ac85;
  font-weight: 600;
}

.form-field {
  position: relative;
}

.form-error {
  position: absolute;
  color: #C83231;
  font-weight: 600;
}

.create-lg-error {
  color: #C83231;
  font-weight: 600;
}

.form-exp {
  font-style: italic;
  font-size: 12px;
}

.photo-exp {
  margin-top: -12px;
  font-style: italic;
  font-size: 12px;
}

.bonus-explainer {
  text-decoration: underline;
  font-weight: 600;
}

.bonus-explainer:hover {
  cursor: pointer;
}

.inline-field {
  display: inline-block !important;
  padding-right: 20px;
}

.row-slim {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.form-radio {
  display: inline-flex !important;
  margin-left: 10px !important;
}

.wide-column {
  width: 75% !important;
  padding-left: 1rem;
  padding-top: 10px;
}

.popup-content {
  font-size: 12px;
}

.reg-btn-column {
  margin-left: 75%;
  button {
    background-color: var(--color-main) !important;
  }
}

.loader-down {
  position: fixed !important;
  left: 40% !important;
  top: 40% !important;
}

.logged-in-already {
  font-size: 36px;
  color: #1873B9;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

form.reset-pw-form {
  input, label, .form-error {
    width: 75% !important;
    margin-left: 12.5% !important;
  }
}
