@import "./variables.scss";

.game-home-grid {
  margin-top: -10px !important;
}

.game-color, a.game-color {
  color: var(--color-main) !important;
}

.white {
  color: white;
}

.nba-banner {
  width: 100%;
  height: 120px;
  border-top: solid 4px var(--color-main);
  border-bottom: solid 4px var(--color-main);
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}

.nba-wp, .nba-pb-ban {
  position: absolute;
  width: 100%;
  max-height: 220%;
  top: -30%;
  opacity: 0.5;
  z-index: -999;
}

.mlb-grid {
  margin-top: 15% !important;
  color: #4483C2;
  font-size: 34px;
  font-weight: 600;
  line-height: 6vh;
}

.mlb-wp {
  position: absolute;
  width: 100%;
  max-height: 220%;
  top: -80%;
  opacity: 0.6;
  z-index: -999;
}

.mlb-pb-ban {
  position: absolute;
  width: 100%;
  max-height: 220%;
  top: -20%;
  opacity: 0.5;
  z-index: -999;
}

.ai-banner {
  position: absolute;
  width: 100%;
  max-height: 220%;
  top: -25%;
  opacity: 0.4;
  z-index: -999;
}

.mlb-fi-banner {
  position: absolute;
  width: 100%;
  max-height: 220%;
  top: -25%;
  opacity: 0.8;
  z-index: -999;
}

.nba-fi-banner {
  position: absolute;
  width: 100%;
  max-height: 220%;
  top: -25%;
  opacity: 0.6;
  z-index: -999;
}

.nba-banner .banner-text {
  font-size: 74px;
  text-align: center;
  margin-top: 30px;
  color: white;
  font-weight: 600;
  text-shadow:
  -1px -1px 0 var(--color-main),
  1px -1px 0 var(--color-main),
  -1px 1px 0 var(--color-main),
  1px 1px 0 var(--color-main);

  @media screen and (min-width: 560px) and (max-width: 999px) {
    font-size: 7.5vw;
  }
}

.card-header {
  color: var(--color-main) !important;
  font-size: 24px;
  font-weight: 600;
  margin-top: 5px;
}

.card-header-alt {
  background-color: var(--color-main) !important;
  div.header {
    color: white !important;
    font-size: 24px;
    font-weight: 600;
    margin-top: 5px;
    margin-left: -5px;
    div.card-lg {
      margin-bottom: -5px;
    }
  }
//   -webkit-box-shadow: 3px 3px 5px 6px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
//   -moz-box-shadow:    3px 3px 5px 6px #ccc;  /* Firefox 3.5 - 3.6 */
//   box-shadow:         3px 3px 5px 6px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

a.card-lg:hover {
  text-decoration: underline !important;
}

.pick-item {
  padding: 0 !important;
  max-height: 60px;
}

.pick-week {
  font-size: 20px;
  font-weight: 600;
  padding: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 60px;
  max-height: 60px !important;
}

.home-first-pick {
  margin-top: 10px !important;
}

.card-meta {
  margin-bottom: 20px !important;
  padding-top: 3px;
  padding-bottom: 10px;
  border-bottom: solid 1px #E1E1E1;
}

.card-meta i {
  margin-left: 3px;
  color: var(--color-main);
  font-weight: 600;
}

.ui.card, .ui.cards>.card {
  width: 100% !important;
}

// .ui.card {
//   -webkit-box-shadow: 3px 3px 5px 6px #ccc !important;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
//   -moz-box-shadow:    3px 3px 5px 6px #ccc !important;  /* Firefox 3.5 - 3.6 */
//   box-shadow:         3px 3px 5px 6px #ccc !important;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
// }

.rendered-lives {
  padding-left: 25px;
}

.pick-img img {
  width: 70px !important;
}

.death-img img {
  width: 40px !important;
}

.pick-divider {
  margin: 15px 0 !important;
}

.no-pick-icon {
  font-size: 30px !important;
  margin-top: 10%;
  padding-top: 10px;
}

.page-movers {
  display: inline;
  float: right;
  font-size: 36px;
  padding-top: 5px;
}

.thumb-up {
  color: var(--color-main);
}

.feed-label {
  padding-top: 7px;
}

.feed-label i {
  color: var(--color-main) !important;
}

.nba-cont {
  z-index: 100;
  width: 1000vw !important;
}

div.ui.feed {
  div.date {
    min-width: 100px !important;
  }
}

.commish-feed-card > div {
    div.ui.feed {
      min-height: 150px;
      max-height: 300px;
      overflow: scroll;
      padding-left: 0px !important;
      margin-left: -4px !important;
    div.content {
      margin-left: 4px !important;
    }
  }
}

.details-card {
  color: white !important;
  font-size: 16px;
  div.content {
    margin-top: -15px !important;
  }
  a.card-lg{
    color: #c5bcbc !important;
    font-size: 14px;
    font-style: italic;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    display: inline-block !important;;
  }
  .detail-grid {
    background-color: white !important;
    color: var(--color-main) !important;
    margin-top: 10px;
    padding-top: 9px;
    @media screen and (max-width: 1350px) {
      font-size: 0.85em !important;
    }
    .detail-subtext {
      padding: 0 15px 5px 14px;
      color: black;
      font-size: 0.9rem;
    }
  }
  div.row {
    padding-bottom: 5px !important;
    padding-top: 0 !important;
    div.column {
      padding-right: 0 !important;
    }
  }
}

.control-grid {
  background-color: white !important;
  color: var(--color-main) !important;
  margin-top: 10px !important;
  padding-top: 9px;
  div.row:not(.control-detail) {
    border-top: solid 1px #b8b6b6;
    padding-top: 7px !important;
  }
  .control-detail {
    color: black;
    font-size: 11px;
    padding: 0 0 5px 0 !important;
    margin-top: -10px !important;
  }
  .control-cat {
    width: 60% !important;
    color: black;
    font-size: 1.5em;
    font-weight: 800;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 1400px) {
      font-size: 1.25em;
    }
  }
  .control-switch {
    width: 40% !important;
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
  }
}

.detail-button-col {
  text-align: right;
  // padding-top: 0 !important;
  button {
    float: right !important;
    background-color: white !important;
    color: var(--color-main) !important;
    margin-top: 5px !important;
    -moz-box-shadow:    1px 1px 1px 1px #969696 !important;
    -webkit-box-shadow: 1px 1px 1px 1px #969696 !important;
    box-shadow:         1px 1px 1px 1px #969696 !important;
    &:hover {
      transform: scale(1.1);
      transition: 0.75s;
      transition-timing-function: ease;
    }
  }
  .detail-info-msg-lg {
    font-size: 13px;
    width: 100%;
    float: right;
    line-height: 1.25;
    margin-top: 5px;
    a {
      color: #7ADDFD;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .detail-info-msg {
    font-size: 13px;
    width: 75%;
    float: right;
    line-height: 1.25;
    margin-top: 5px;
  }
}

.details-login-box {
  background-color: white !important;
  width: 60%;
  float: right;
  text-align: center;
  border-radius: 10px;
  color: var(--color-main);
  margin-top: 3px;
  padding-bottom: 2px;
  span {
    font-size: inherit !important;
  }
}

.event-feed-card > div {
  div.ui.feed {
    height: 600px;
    overflow: scroll;
    padding-left: 0px !important;
    margin-left: -4px !important;
  }
  div.content {
    margin-left: 4px !important;
  }
}

.event-feed-card > div > div.meta.card-meta, .commish-feed-card > div > div.meta.card-meta {
  margin-bottom: -5px !important;
}

.commish-feed-text {
  font-size: 0.85em !important;
  margin-left: -2.5em !important;
}

.commish-link-text {
  max-width: 300px;
  font-size: 0.75em !important;
  margin-left: -2.5em !important;
}

.feed-filter {
  float: right;
}

.feed-filter i {
  color: var(--color-main);
  font-weight: 600;
}

#root > div > div.ui.container.nba-cont > div > div.ui.bottom.attached.segment.active.tab.nba-content > div > div.ui.three.column.grid.card-grid > div:nth-child(1) > div, #root > div > div.ui.container.nba-cont > div > div.ui.bottom.attached.segment.active.tab.nba-content > div > div.ui.three.column.grid.card-grid > div:nth-child(2) > div, #root > div > div.ui.container.nba-cont > div > div.ui.bottom.attached.segment.active.tab.nba-content > div > div.ui.three.column.grid.card-grid > div:nth-child(3) > div {
  width: auto !important;
}

div.ui.grid.home-user-picks > div:nth-child(odd), div.ui.grid.bonus-leaderboard > div:nth-child(odd) {
  background-color: #F0F1F6;
}

div.ui.grid.home-user-picks {
  display: block !important;
}


div.ui.grid.bonus-leaderboard {
  height: 275px;
  overflow: scroll;
  display: block;
  .bonus-user {
    font-size: 20px;
    font-weight: 600;
    height: 40px;
    padding: 0 0 0 5px !important;
    display: flex !important;
    align-items: center;
  }
  .bonus-amt {
    font-size: 20px;
    font-weight: 600;
    height: 40px;
    max-height: 40px !important;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
  }
  .label-row {
    padding: 0 0 0 5px !important;
    text-align: left;
  }
}

.label-row {
  padding: 0 !important;
  text-align: center;
  font-weight: 600;
  font-size: 10px;
  font-style: italic;
  background-color: #969696 !important;
  color: white;
  height: 20px;
  .login-span {
    color: #7ADDFD;
    font-size: 14px;
  }
  a {
    color: #7ADDFD;
    font-size: 12px;
  }
  .login-span:hover, a:hover {
    cursor: pointer;
    color: #C63436;
  }
}

.user-sel-card {
  min-height: 350px !important;
}

.no-pool-picks {
  font-size: 24px;
  font-weight: bold;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-main);
}

.active-toggle-btn:hover {
  cursor: default !important;
}

.active-toggle-btn.negative:hover {
  background-color: #db2828 !important;
}

.active-toggle-btn.positive:hover {
  background-color: #21ba45 !important;
}

.page-subheader {
  margin: 0 0 -25px 0 !important;
  padding-top: 4px !important;
  .game-susp {
    width: 100%;
    padding: -10px !important;
    a {
      color: #99e4fc;
    }
  }
}

button.buy-insurance {
  color: var(--color-main) !important;
  border: solid 1px var(--color-main) !important;
  background: transparent !important;
  box-shadow: 0 0 0 1px #f2711c inset !important;
  &:hover {
    background-color: var(--color-main) !important;
    color: white !important;
  }
  @media screen and (max-width: 1400px) {
    font-size: 12px !important;
  }
}

.manage-entry {
  div.column {
    padding-top: 3px !important;
    padding-bottom: 6px !important;
  }
  .detail-info-msg {
    margin-top: 0px;
    font-size: 10px !important;
  }
}