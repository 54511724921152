#field{
    position:absolute;
    top:-44px;
    left:58px;
    height:280px;
    width:280px;
    border-radius:1% 100% 1% 1%;
    border:1px solid green;
    background-color:green;
   -webkit-transform: rotate(-45deg);
}
#counter {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: inline-block;
  width: 33px;
  border-radius: 5px;
  border: solid 2px black;
  background-color: white;
}
#summary{
  position: absolute;
  top: 10px;
  left: 10px;
  padding-right: 5px;
  display: inline-block;
  border-radius: 5px;
  border: solid 2px black;
  background-color: white;
}
#in-field{
    position:absolute;
    top:68px;
    left:106px;
    height:180px;
    width:180px;
    border-radius:1% 50% 1% 1%;
    border:1px solid #836539; /*#9b7653; */
    background-color:#836539; /*#9b7653;*/
   -webkit-transform: rotate(-45deg);
}
#base-lines{
    position:absolute;
    top: -48px;
    left: 62px;
    height:268px;
    width:268px;
    border-left:2px solid white;
    border-bottom:2px solid white;
    -webkit-transform: rotate(-45deg);
}
#in-field-grass{
    position:absolute;
    top: 132px;
    left: 143.6px;
    height:104px;
    width:104px;
    border:2px solid green;
    background-color:green;
    -webkit-transform: rotate(-45deg);
}
.base{
    position:absolute;
    height:5.2px;
    width:5.2px;
    -webkit-transform: rotate(-45deg);
    border:1px solid white;
    background-color:white;
    z-index: 1000;
}
#first-base{
    top:180px;
    left:282.4px;
}
#second-base{
    top:92.4px;
    left:193.6px;
}
#third-base{
    top:180px;
    left:105px;
}
#third-runner-name{
    position:absolute;
    top:194px;
    width: 20%;
    margin-left: 16%;
    display: flex;
    justify-content: center;
    z-index: 999
}
#hitters-logo {
  position: absolute;
  top: 240px;
  width: 95%;
  margin-left: 5%;
  display: flex;
  justify-content: center;
  z-index: 999;
}
.di-name {
  div {
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    background-color:#cdcdcd;
    border-radius: 10px;
    display: inline-block;
    border: 1px solid;
    padding: 0 5px !important;
  }
}
#hitters-name{
    position:absolute;
    top:280px;
    width: 96%;
    margin-left: 4%;
    display: flex;
    justify-content: center;
}
#first-runner-name{
    position:absolute;
    top:194px;
    width: 20%;
    margin-left: 69%;
    display: flex;
    justify-content: center;
    z-index: 999
}
#pitchers-name{
    position:absolute;
    top:190px;
    width: 96%;
    margin-left: 4%;
    display: flex;
    justify-content: center;
}
#home-plate{
    position:absolute;
    height:2.8px;
    width:5.2px;
    top:271.2px;
    left:194px;
    border:1px solid white;
    background-color:white;
}
#home-plate:before, #home-plate:after{
	top: 100%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}
#home-plate:before{
	border-top-color:white;
	border-width: 3.2px;
	left: 50%;
	margin-left: -3.8px;
}
.home-plate-pulse{
    /* Copy position attributes from #home-plate.
       Needs to be separate to allow batters boxes to cover edges of home plate */
    position:absolute;
    height:7px;
    width:13px;
    top:678px;
    left:485px;
}
#pitchers-logo {
  position: absolute;
  top: 150px;
  width: 95%;
  margin-left: 5%;
  display: flex;
  justify-content: center;
  z-index: 999;
}
#pitchers-mound{
    position:absolute;
    top:168px;
    left:180px;
    height:32px;
    width:32px;
    border-radius:100% 100% 100% 100%;
    -webkit-transform: rotate(-45deg);
     border:1px solid #836539;
    background-color:#836539;
}
#pitchers-plate{
    position:absolute;
    top:183.2px;
    left:192.8px;
    height:1.6px;
    width:6.4px;
    border:1px solid white;
    background-color:white;
}
#vertical{
    position:absolute;
    top:5.6px;
    left:196.4px;
    height:446px;
    width:1px;
    border-left:1px solid black;
}
.half-circle{
    position:absolute;
    height:16px;
    width: 16px;
    -webkit-transform: rotate(-45deg);
    border:1px solid #836539;
    background-color:#836539;
}
#third-circle{
    top:176px;
    left:120px;
    border-radius:1% 1% 100% 1%;
}
#first-circle{
    top:176px;
    left:256.8px;
    border-radius:100% 1% 1% 1%;
}

#second-circle{
    top:110px;
    left:188px;
    border-radius:1% 1% 1% 100%;
}
#second-runner-name{
    position:absolute;
    top: 105px;
    width: 20%;
    margin-left: 42%;
    display: flex;
    justify-content: center;
    z-index: 999
}
.batters-box{
    position:absolute;
    height:10px;
    width: 6px;
    border:2px solid white;
    background-color:#836539;
      /*-6px 6px red,
      -6px -6px red,
      6px -6px red;*/
    opacity: 1.0; /* Needed so that opacity for pulse doesn't cause issues */
    top: 268px;
}
#batters-box-right{
    box-shadow: 2.4px 0 #836539; /* Cover up the base line */
    left:181px;
  margin-top: 3px;
}
#batters-box-left{
  box-shadow: -2.4px 0 #836539; /* Cover up the base line */
    left:204px;
  margin-top:3px;
}
#batting-circle{
    position:absolute;
    top:255px;
    left:177px;
    height:40px;
    width:40px;
    border-radius:100% 100% 100% 100%;
   -webkit-transform: rotate(-45deg);
     border:1px solid #836539;
    background-color:#836539;
}
#first-base-thing{
    top:172px;
    left:272px;
    position:absolute;
    z-index: 990;
    height:58px;
    width:10px;
    -webkit-transform: rotate(45deg);
    border-right:2px solid white;
    border-bottom:2px solid white;
}

.mowed-grass{
     background-color: #ac0;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, rgba(255, 255, 255, .2)), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, rgba(255, 255, 255, .2)), color-stop(.75, rgba(255, 255, 255, .2)), color-stop(.75, transparent), to(transparent));
background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
background-image: -ms-linear-gradient(0deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
background-image: -o-linear-gradient(0deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
background-image: linear-gradient(0deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
    -webkit-background-size: 250px 250px;
    -moz-background-size: 50px 50px;
    background-size: 200px 200px;
/* via http://lea.verou.me/2010/12/checkered-stripes-other-background-patterns-with-css3-gradients/ */

    background-color:#ac0
}

.action-area{
	  position: relative;
	  z-index: 2000;
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin: -20px;
}

.pulse {
    z-index: 1000;
    // background: red;
    border: 1px solid #999;
    // border-color: blue;
    border-radius: 100%;
    margin: -4px; /* -0.5 * baseWidth - baseBorder */
    width: 11px; /*  2 * baseWidth + baseBorder */
    height: 11px;
    -webkit-animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0.0;
}

@-webkit-keyframes pulsate {
    0% {-webkit-transform: scale(1.0, 1.0); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(2.0, 2.0); opacity: 0.0;}
}
@keyframes pulsate {
    0% {-webkit-transform: scale(1.0, 1.0); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(2.0, 2.0); opacity: 0.0;}
}
