@import "./variables.scss";

img.home-bg {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;

  opacity: 0.15;

  z-index: -999;
}

.nba-menu {
  margin-top: -53px;
  div.pagination {
    a.item {
      color: black !important;
      font-weight: normal !important;
    }
    a.item.active {
      color: white !important;
    }
  }
  a.item {
    color: white !important;
    font-weight: bold !important;
  }
}

.page-header {
  i {
    // color: var(--color-main) !important;
  }
}

.page-header.nba {
  background-color: #f4f4f4 !important;
  margin-bottom: -10px;
  position: relative !important;
}

.page-header.nba.finances::before, .page-header.nba.bonuses::before, .page-header.commish::before,  {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  opacity: 0.10;
}

.page-header.nba.finances::before  {
    background: url('https://alasurvivor.s3-us-west-2.amazonaws.com/images/ai-money.jpg') no-repeat center center;
}

.page-header.nba.bonuses::before,  {
    background: url('https://alasurvivor.s3-us-west-2.amazonaws.com/images/harden-wp-2.jpg') no-repeat center center;
}

.page-header.commish::before,  {
    background: url('https://alasurvivor.s3-us-west-2.amazonaws.com/silver.jpg') no-repeat center center;
}

div.ui.bottom.attached.segment.active.tab.nba-content {
  background: rgba(0, 0, 0, 0);
}

div.nba-menu > div.ui.attached.tabular.menu > a.active.item {
  margin-bottom: 0px !important;
  background-color: var(--color-main) !important;
  color: white !important;
}

a.item {
  &:hover {
    cursor: pointer !important;
  }
}

div.nba-menu > div.ui.attached.tabular.menu {
  border-bottom: solid 2px var(--color-main) !important;
}

.login-error {
  text-align: center;
  background-color: #9FA7AE;
  font-weight: 600;
  color: white;
  padding: 5px;
  margin: 5px;
  border: solid 3px var(--color-main);
  .login-span, a {
    color: #7ADDFD;
  }
  .login-span:hover, a:hover {
    cursor: pointer;
    color: #C63436;
  }
}

.game-susp {
  text-align: center;
  background-color: var(--color-main);
  font-weight: 600;
  color: white;
  padding: 5px;
  margin: 5px;
  border: solid 3px #D6D4D6;
}
