@import "./variables.scss";

.how-to-play-tab {
  background-color: #f4f4f4 !important;
}

.full-theme-banner {
  background-color: var(--color-main);
  font-size: 1.3em;
  color: white;
  font-weight: 600;
  height: 1.5em;
  display: flex;
  align-items: center;
  padding: 10px 10px 12px 10px;
}

.full-theme-header {
  color: var(--color-main);
  font-size: 1.5em;
  font-weight: 600;
  height: 1.5em;
  display: flex;
  align-items: center;
  padding: 10px 10px 12px 10px;
  justify-content: center;
  width: 100%;
}

.how-to-play-div {
  font-size: 1.25em;
  line-height: 1.4;
  i {
    color: var(--color-main);
  }
}

.how-to-play-sec {
  padding: 14px;
}

.how-to-play-sec-2 {
  padding: 20px;
}

@media screen and (max-width: 800px) {
  .bonus-table {
    width: 90% !important;
  }
}

.normal-season {
  margin-left: 20px;
  text-decoration: line-through;
}

.strike {
  text-decoration: line-through;
}

.dark-red {
  color: #9e0404;
  font-weight: bold;
}

.how-to-exp {
  margin-top: -12px;
  font-size: 1em;
}

.how-to-icon-col {
  margin-right: -3px;
}

.how-to-val {
  color: var(--color-main);
}

.how-to-bonus-label {
  position: absolute;
  top: -1rem;
  font-size: 1rem;
  font-style: italic;
  font-weight: bold;
}

#how-to-bonus-list {
  font-size: 1em !important;
}
