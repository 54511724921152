@import "./variables.scss";

.header-container {
  width: 1140px;
  margin: -5px 1em !important;
  .menu-game {
    font-size: 16px;
  }

  @media screen and (min-width: 560px) and (max-width: 999px) {
    margin: -5px 1vw !important;
  }

  @media screen and (min-width: 1141px) and (max-width: 1350px) {
    width: 90vw !important;
    margin: -5px 5vw !important;
  }

  @media screen and (min-width: 1351px) {
    width: 1350px !important;
    margin: 5px calc((100% - 1350px)/2) 10px calc((100% - 1350px)/2) !important;
  }
}

.logo-right {
  padding-left: 4px !important;
  font-weight: 800;
}

.header-login {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ui.visible.left.overlay.sidebar, .animating.ui.overlay.sidebar {
  margin-top: 40px !important;
  z-index: 999;
}

.mobile-login-div {
  margin-top: -10px !important;
}

.main-login {
  display: flex;
  align-items: center;
}

// insane styling necessary to get header links white on all pages ...
#root > div > div > div:nth-child(2) > div > div > div.item.menu-leagues > div > div.ui.visible.menu > a, div.item.menu-leagues > div > div.ui.visible.menu > a, div.item.menu-leagues > div > div.ui.visible.menu > div > div.ui.visible.menu > a, div.item.menu-leagues > div > div.ui.menu > a, div.item.menu-leagues > div > div.ui.menu > div > div.ui.menu > a, #root > div > div > div.header-container > div > div.item.menu-leagues > div > div.ui.visible.menu > a, #root > div > div > div.header-container > div > div.item.menu-leagues > div > div.ui.simple.dropdown > a, #root > div > div > div.header-container > div > div.item.menu-leagues > div > div.ui.simple.dropdown > a, #root > div > div > div.header-container > div > div.item.menu-leagues > div > div.ui.menu > a, #root > div > div > div:nth-child(2) > div > div > div.item.menu-leagues > div > div.ui.menu > a, #root > div > div > div.header-container > div > div.item.menu-leagues > div > div.ui.menu > div > div.ui.menu > a, #root > div > div > div.header-container > div > div.right.item > div > div > div.ui.visible.menu > a, #root > div > div > div.header-container > div > a:nth-child(5) > div > div.ui.menu > div > div.ui.menu > a, #root > div > div > div.header-container > div > a:nth-child(6) > div > div.ui.menu > div > div.ui.menu > a {
  color: white !important;
}

@media screen and (max-width: 999px) {
  .menu-leagues.ala {
    margin-left: -20px !important;
    padding-left: 0 !important;
  }
}

.menu-leagues {
  padding-right: 0px !important;
  .ala {
    margin-left: -20px;
  }
  div {
    padding-bottom: 1px !important;
    padding-top: 5px !important;
    font-weight: bold;
    font-size: 16px;
    .ui.dropdown.item div, div.ui.visible.menu > div > i, div.ui.menu > div > i {
      color: white !important;
    }
    div.ui.visible.menu > div > div.ui.visible.menu > a > img, div.ui.menu > div > div.ui.menu > a > img {
      vertical-align: middle !important;
    }
  }
  i.dropdown.icon {
    margin: 0 0 0 0.25em !important;
    padding-top: 3px !important;
  }
  i {
    position: absolute;
    left: 2px !important;
    font-size: 1.4em !important;
    padding-top: 5px;
  }
  div.ui.menu {
    margin-top: 3px !important;
    margin-left: -25% !important;
    background-color: #282a2e !important;
    div.item i {
      padding-top: 0;
      padding-left: 10px;
    }
  }
}

.header-user-img {
  max-width: 25px !important;
  margin-right: -8px;
  margin-bottom: -12px;
}

.header-user-icon {
  margin-bottom: -4px !important;
  margin-right: -5px !important;
}

.global-header-link {
  span {
    margin-left: 10px;
    font-weight: 700 !important;
  }
}

#root > div > div > div.header-container > div > div:nth-child(5) > div > div.ui.menu > a > i, #root > div > div > div.header-container > div > div:nth-child(6) > div > div.ui.menu > a > i, #root > div > div > div:nth-child(2) > div > div > div:nth-child(5) > div > div.ui.menu > a > i, #root > div > div > div:nth-child(2) > div > div > div:nth-child(6) > div > div.ui.menu > a > i {
  margin-top: -5px !important;
}
