@import "./variables.scss";

.lifeIcon:hover {
  cursor: pointer;
}

.actionWarning {
  margin: 10px 0;
  color: #a00003;
}

.checkShade {
  label {
    color: #6638f0;
  }
}

.weeksAliveSpan {
  margin-left: 5;
}

.trophy-icon {
  margin-left: 5px !important;
  margin-top: -7px !important;
}

.addEntrantDropdown {
  // margin-left: 10px;
  min-width: 500px !important;
  // div.visible.menu.transition {
  //   max-height: 250px;
  //   overflow: scroll;
  // }
}

.weeksAliveDropdown {
  margin-left: 10px;
  width: 40px;
  div.visible.menu.transition {
    max-height: 250px;
    overflow: scroll;
  }
}

#commish-menu {
  a.item {
    color: var(--color-main) !important;
    font-weight: bold !important;
  }
}

.commish-tools-cont {
  padding: 5px 10px !important;

}

table#manage-entrants {
  .lives-adj-cell {
    display: flex !important;
    align-items: center !important;
    border-left: 1px solid rgba(34, 36, 38, 0.1) !important;
  }
}

.edit-user-pick-row {
  margin-bottom: 5px;
  border-radius: 10px;
  padding: 5px;
  display: flex !important;
  align-items: center !important;

  font-size: 2.5em;
  div.visible.menu {
    width: 75px !important;
  }
}

.edit-pick-wk-col {
  text-align: right !important;
}

.edit-pick-wk {
  color: var(--color-main);
  margin-right: 2em;
}

img.edit-pick-logo {
  max-width: 1.75em !important;
  padding-top: 0px !important;
  display: inline-block !important;
}
