@import "./variables.scss";

.landing-grid {
  margin-top: 0 !important;
}

#landing-login {
  @media screen and (max-width: 773px) {
    display: none;
  }
  position: absolute;
  right: 2%;
  top: 2%;
  .login-span {
    font-size: 28px !important;
    color: white !important;
  }
}

#landing-login > div > div.ui.visible.menu, #landing-login > div > div.ui.menu {
  a {
    color: white !important;
  }
  background-color: black !important;
}

.splash-left-col {
  background: rgba(0, 0, 0, 0.6);
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 42% !important;
  padding: 10px 0 !important;
  @media screen and (max-width: 450px) {
    font-size: 4.2vw;
  }
  @media screen and (max-width: 773px) {
    width: 100% !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  @media screen and (min-width: 774px) and (max-width: 914px) {
    width: 70% !important;
  }
  @media screen and (min-width: 915px) and (max-width: 1161px) {
    width: 60% !important;
  }
  @media screen and (min-width: 1162px) and (max-width: 1601px) {
    width: 55% !important;
  }
  padding: 0 2% !important;
  .splash-header {
    color: #7c7c7c;
    font-size: 1.4em;
    margin-top: 10px;
    margin-bottom: -10px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    span {
      margin: 0 10px;
      font-size: 0.4em !important;
    }
    &.top {
      margin-top: 20px;
      @media screen and (max-width: 773px) {
        margin-top: 10px !important;
        margin-bottom: -17px !important;
      }
    }
    @media screen and (max-width: 773px) {
      font-size: 0.8em;
      margin-top: 5px !important;
      margin-bottom: -20px !important;
    }
  }
  .splash-menu {
    border-top: solid 3px white;
    border-bottom: solid 3px white;
    width: 70%;
    margin-left: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    height: 40px;
    margin-top: 20px;
    .menu-item {
      margin: 4%;
      font-weight: 600;
      color: white;
      font-size: 1.2vw;
      white-space: nowrap;
      display: flex;
      i {
        vertical-align: middle;
        @media screen and (max-width: 773px) {
          margin-bottom: 5px !important;
        }
      }
      &:hover {
        color: #c73230;
      }
      @media screen and (max-width: 450px) {
        font-size: 4.2vw;
      }
      @media screen and (min-width: 451px) and (max-width: 773px) {
        font-size: 3.4vw
      }
      @media screen and (min-width: 774px) and (max-width: 914px) {
        font-size: 2vw;
      }
      @media screen and (min-width: 915px) and (max-width: 1161px) {
        font-size: 1.5vw;
      }
    }
  }
  .splash-img {
    width: 80%;
    margin-left: 10%;
    margin-top: 5px;
  }
  .splash-highlights {
    @media screen and (max-width: 773px) {
      div.column {
        padding: 0px 5px !important;
      }
    }
    h2.header {
      div {
        display: block;
      }
      div.lead {
        color: white;
        font-size: 1.4em;
        @media screen and (max-width: 773px) {
          font-size: 0.8em;
          line-height: 1;
        }
      }
      i, svg {
        color: #c73230;
        font-size: 3em !important;
        @media screen and (max-width: 773px) {
          font-size: 2em !important;
        }
      }
    }
    div.sub.header {
      color: #a8a6a6;
      font-size: 18px;
      margin-top: 5px;
      @media screen and (max-width: 773px) {
        font-size: 12px !important;
      }
    }
  }
}

.container.landing-bg {
  /* Set rules to fill background */
  min-height: 100vh;
  min-width: 1024px;
  max-width: 100%;

  background: url("../../images/lillard_game_winner.jpg") no-repeat center / cover;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;

  opacity: 0.6;
  z-index: -999;

  @media screen and (max-width: 772px) {
    left: 20%;
    margin-left: -500px;   /* 50% */
    max-width: 100%;
  }
}



.master-container {
  width: 1140px !important;
  margin: 0 1em !important;
  min-height: 1200px !important;

  @media screen and (min-width: 560px) and (max-width: 999px) {
    margin: 1em 1vw !important;
  }

  @include desktop {
    // width: 90vw !important;
    margin: 1em 5vw !important;
  }

  @media screen and (min-width: 1351px) {
    width: 1350px !important;
    margin-left: calc((100% - 1350px)/2) !important;
  }
}

.mobile-pg-adjust {
  /* position: absolute; */
  /* top: 50px; */
  margin-bottom: calc(-100vh + 40px);
}

.built-by {
  font-size: 1.2vw;
  position: absolute;
  bottom: 0;
  font-style: italic;
  text-align: center;
  width: 100%;
  color: #969696;

  @media screen and (max-width: 559px) {
    font-size: 3.5vw;
  }

  @media screen and (min-width: 560px) and (max-width: 1140px) {
    font-size: 2.5vw;
    bottom: 1%;
  }

  @media screen and (min-width: 1141px) {
    font-size: 1.25vw;
    bottom: 1%;
  }

  @media screen and (min-width: 1800px) {
    font-size: 1.4vw;
    bottom: 1%;
  }
}
