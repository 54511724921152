@import "./variables.scss";

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

// .montserrat{font-family: 'Montserrat'}
// .bold{font-weight: 700;}
// .mega {
//   font-size: 4rem; /* 72 / 18 = 4 */
//   margin-bottom: 0.4125rem;
// }

.get-started-container {
  width: 1140px !important;
  margin: 0 1em !important;
  min-height: 1200px !important;

  // @media screen and (min-width: 560px) and (max-width: 999px) {
  //   margin: 1em 1vw !important;
  // }

  // @include desktop {
  //   // width: 90vw !important;
  //   margin: 1em 5vw !important;
  // }

  @media screen and (min-width: 1351px) {
    width: 1350px !important;
    margin-left: calc((100% - 1350px)/2) !important;
  }
}

.get-started-header {
  margin-top: -275px;
  height: 275px;
  z-index: 2;
  font-size: 72px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  line-height: 1;
  font-family: 'Montserrat';
  display: flex;
  align-items: center;
  div.logo-cont {
    width: 30%;
    margin-top: -60px;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
  }
  div.text-cont {
    width: 70%;
    margin-top: -60px;
    margin-left: 40px;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
  }
}

.get-started-logo {
  width: 60%;
}

.get-started-buttons {
  position: absolute;
  left: 0;
  top: -80px;
  .button {
    color: #c73230 !important;
    border: solid 3px #c73230 !important;
    background-color: black !important;
  }
  .active.button {
    background-color: #c73230 !important;
    // border: #c73230 !important;
    color: white !important;
  }
}

#get-started-grid {
  margin-top: 0px;
  div.content {
    color: #c73230;
  }
  div.row {
    margin-top: 30px;
  }
  img {
    border-radius: .28571429rem;
  }
  img:hover {
    cursor: pointer;
  }
  padding-bottom: 30px;
}

.get-started-grid-2 {
  margin-top: -10px !important;
  margin-bottom: 10px !important;
}

.link-hover {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.start-here-menu {
  a:not(.active) {
    &:hover {
      cursor: pointer;
    }
  }
  a.active {
    background-color: #c73230 !important;
    color: white !important;
    font-weight: 600 !important;
    &:hover {
      cursor: default !important;
    }
  }
}

.join-pool-instructions {
  i {
    color: black !important;
  }
  div.meta i {
    color:#9e0404 !important;
  }
  div.item-group-image-container {
    width: 12%;
    padding-top: 50px;
    img {
      margin-bottom: 15px;
      border: dashed #9e0404 1px;
      box-shadow: 0px 5px 10px 1px;
    }
    
  }
  div.image img {
    &:hover {
      cursor: pointer;
    }
  }
}

a.active.item {
  i.icon {
    color: white;
  }
}


