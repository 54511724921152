@import "./variables.scss";

.ala-login-header {
  text-align: center;
  margin-bottom: 15px;
}

.g-signin-btn:hover {
  cursor: pointer;
}

.ala-login-btn {
  float: right;
  margin-top: 5px !important;
}

.forgot-pw-field {
  width: 50%;
}

.login-form {
  float: right;
}

.login-form label {
  margin-right: 10px;
}

.forgot-pw {
  color: #2185d0;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.forgot-pw-container {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  margin-bottom: -10px;
}

.forgot-pw-btn {
  float: center;
  margin: 5px;
}

.return-login {
  color: #d4d4dc;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.login-footer {
  text-align: center;
  margin: 10px -20px -5px -20px;
  background-color: #E1E1E1;
  z-index: 999;
  height: 30px;
  padding-top: 5px;

  @media screen and (max-width: 650px) {
    margin: 10px 0 20px 0;
    width: 100%;
    text-align: center;
    display: block;
    background-color: white;
  }
}

.login-span {
  color: #1BA4DE;
  font-weight: 600;
  font-size: 16px;
}

.login-span:hover {
  cursor: pointer;
  color: #C63436;
}

.inline {
  display: inline !important;
}

.flex-center {
  display: flex;
  align-items: center;
}
