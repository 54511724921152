.error-color {
  color: #a00003 !important;
}

.context-icon {
  position: absolute;
  top: -0.25em;
}

.context-popup {
  background-color: var(--color-main);
  max-width: 400px !important;
  opacity: 0.95;
  .header {
    text-align: center;
    color: var(--color-main);
    font-weight: 800;
    span {
      margin-bottom: 5px;
    }
  }
}

.page-header {
  background-color: #edebf4;
  margin-top: -1.25rem !important;
  border-bottom: solid 1px black;
  padding-bottom: 1.5rem !important;
  z-index: 2;
  div.ui.inverted.vertical.menu {
    background: var(--color-main) !important;
  }
}

.page-header.create-pool {
  min-height: 140px;
  padding-bottom: 5px !important;
}

.nba-color {
  color: #D06C35;
}

.mlb-color {
  color: #042F70;
}

.iconify-icon {
  font-size: 1.75em;
}

#get-started-grid {
  .iconify-icon {
    margin: -5px 5px 0 0;
  }
}

.page-header {
  .iconify-icon  {
    margin: -3px 10px 0 0;
    font-size: 3em;
  }
}

i.flipped {
  -moz-transform: scale(1, -1);
-webkit-transform: scale(1, -1);
-o-transform: scale(1, -1);
-ms-transform: scale(1, -1);
transform: scale(1, -1);
}
