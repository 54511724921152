@import "./variables.scss";

.create-league-form {
  width: 90%;
  margin-left: 7%;
  margin-top: 30px;
}

.league-banner {
  width: 100%;
  height: 130px;
  border-top: solid 4px var(--color-lg);
  border-bottom: solid 4px var(--color-lg);
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    width: 100%;
    max-height: 220%;
    top: -40%;
    opacity: 0.5;
    z-index: -999;
  }
}

.league-banner .banner-text {
  font-size: 3vw;
  text-align: center;
  margin-top: 30px;
  color: white;
  font-weight: 600;
  text-shadow:
  -1px -1px 0 var(--color-lg),
  1px -1px 0 var(--color-lg),
  -1px 1px 0 var(--color-lg),
  1px 1px 0 var(--color-lg);

  @media screen and (min-width: 560px) and (max-width: 999px) {
    font-size: 7.5vw;
  }
}

.league-menu {
  margin-top: -60px;
  a.item {
    color: white !important;
    font-weight: bold !important;
  }
}

div.league-menu > div.ui.attached.tabular.menu > a.active.item {
  margin-bottom: 0px !important;
  background-color: var(--color-main) !important;
  color: white !important;
}

div.league-menu > div.ui.attached.tabular.menu {
  border-bottom: solid 2px var(--color-main) !important;
}

.purple-text {
  color: #6638f0 !important;
}

.purple-bg {
  background-color: #6638f0;
}

.email-button {
  .ui.button, .ui.buttons .button {
    background-color: #6638f0 !important;
    color: #fff;
    text-shadow: none;
    background-image: none;
    margin-top: 5px;
  }
}

.space {
  margin-bottom: 10px;
}

.invite-box {
  width: 100%;
  text-align: center;
  border: solid 3px var(--color-main);
  border-radius: 5px;
  background-color: white;
  font-size: 36px;
  padding: 10px;
  button {
    vertical-align: middle !important;
    background-color: var(--color-main) !important;
  }
  div {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  span.login-span {
    font-size: 36px !important;
  }
}

.league-error {
  div {
    margin-top: 20px;
  }
}

.summary-header {
  text-align: center;
  background-color: var(--color-main);
  color: white;
  font-weight: 800;
}

.game-mgmt-header {
  font-size: 48 !important;
  display: inline;
  position: relative;
  color: var(--color-main);
}

.game-mgmt-header:after {
  content: "";
 background: var(--color-main);
 position: absolute;
 bottom: -10%;
 left: 10%;
 height: 1px;
 width: 80%;
}

.game-mgmt-subtext {
  font-size: 16px;
  position: absolute;
  left: 20% !important;
  bottom: -92% !important;
  color: black;
  .game-mgmt-start {
    margin-top: 5px;
  }
}

.game-mgmt-toggle-cont {
  margin-left: 30;
  margin-top: 20;
  div.ui.checkbox {
    margin-left: 8%;
    label {
      font-size: 20px !important;
    }
  }
}

.upcEventGrid {
  margin-top: 0 !important;
  div.ui.segment {
    i {
      font-size: 3.5rem;
      padding-top: 20%;
    }
  }
  div.ui.input {
    width: 100px !important;
  }
  div.ui.input.wider {
    width: 130px !important;
    input[type=number] {
      padding: 0;
    }
  }
  div.ui.labeled.input {
    position: absolute;
    bottom: -35px;
    left: 15px;
  }
}

// #root > div > div > div.master-container > div.league-menu > div.ui.bottom.attached.segment.active.tab > div > div:nth-child(1) > div.ui.grid.upcEventGrid > div:nth-child(3) > div:nth-child(3) > div > div > div.eleven.wide.column > h2 > div > div > input[type=number]

.game-mgmt-select {
  margin-left: 5px !important;
  font-size: 20px !important;
  color: var(--color-main) !important;
}

.confirm-row {
  margin-bottom: 30px;
  margin-top: 20px !important;
  button {
    background-color: var(--color-main) !important;
    color: white !important;
  }
}

.ui.toggle.checkbox input:focus:checked~.box:before,
.ui.toggle.checkbox input:focus:checked~label:before,
.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
    background-color: var(--color-main) !important;
}

.ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before {
    background-color: grey;
}

.placeholder-msg {
  position: absolute;
  top: 70%;
  z-index: 999;
  font-style: italic;
  color: #8175a8;
}

div.ui.grid.trophy-grid > div:nth-child(odd), div.ui.grid.bonus-leaderboard > div:nth-child(odd) {
  background-color: #F0F1F6;
}

div.ui.grid.trophy-grid {
  display: block !important;
  font-weight: 800;
  font-size: 18px;
  .nba {
    color: #D06C35;
  }
  .mlb {
    color: #042F70;
  }
  div.row {
    display: flex;
    align-items: middle;
  }
}

.pool-trophy {
  display: flex;
  flex-flow: column;
  padding-top: 12px;
  i {
    font-size: 3vw !important;
    height: 0 !important;
    @media screen and (max-width: 1024px) {
      font-size: 36px !important;
    }
  }
  .trophy-text {
    font-size: 1vw;
    @media screen and (max-width: 1024px) {
      font-size: 11px !important;
    }
    @media screen and (min-width: 1900px) {
      font-size: 18px !important;
    }
    line-height: 1;
    display: inline-block;
    margin-top: 10px !important;
  }
}

.pool-trophy.mlb {
  a {
    color: #042F70 !important;
    &:hover {
      color: #4183c4 !important;
    }
  }
}

.pool-trophy.nba {
  a {
    color: #D06C35 !important;
    &:hover {
      color: #4183c4 !important;
    }
  }
}

.create-pool-step {
  font-size: 3.5rem;
  padding-top: 20%;
  font-weight: bold;
}

.league-label {
  color: #6638f0;
  font-size: 2rem;
  font-style: italic;
  margin-bottom: -5px;
}

.league-header {
  position: absolute;
  top: 0px;
  font-size: 1rem;
  font-style: italic;
  color: #c5bcbc !important;
  .ui.header {
    padding-top: 1rem !important;
    padding-bottom: 0.5rem;
  }
}

.league-header-cont {
  padding-top: 5px;
  margin-left: -8px;
  padding-bottom: -5px;
}

.button-subtext {
  margin-top: 5px;
  color: rgba(0,0,0,.6);
}

.league-card {
  table, tbody, tr, td {
    border: none !important;
  }
  div.description {
    margin-left: -1rem;
    margin-right: -1rem;
    padding-right: 0.3rem;
  }
  tr.nba {
    i.user.outline.icon {
      color: var(--color-nba);
      font-weight: 600;
    }
  }
  tr.mlb {
    i.user.outline.icon {
      color: var(--color-mlb);
      font-weight: 600;
    }
  }
}

.league-card-main .content {
  padding: 1em 0 !important;
}

.purple-text-dim {
  color: #7d62cc;
}

.login-div, .login-div span {
  font-size: 32px;
  margin-top: 60px;
  margin-bottom: 40px;
  text-align: center;
}

div.input.bonus-option {
  width: 100px !important;
  input[type=text] {
    max-width: 60px !important;
  }
}

.inactive-bonus {
  color: #a8a6a6;
  background-color: grey;
}

a.pool-link {
  font-weight: 600;
}

.member-desc {
  max-height: 800px;
  overflow-y: scroll;
}

.member-header {
  padding-bottom: 8px;
  border-bottom: solid 1px #cdc7df;
}

.league-detail-header {
  background-color: var(--color-main) !important;
  padding: 5px;
  margin: -1em -1em 0 -1em !important;
  .league-name-cont {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    @media screen and (min-width: 1040px) {
      padding-top: 1%;
    }
    .card-header {
      color: white !important;
      font-size: 3em;
      line-height: 1;
    }
    .meta {
      color: #cdc7df;
      margin-top: 12px;
    }
  }
  .league-logo-cont {
    width: 50%;
    display: inline-block;
    img {
      max-height: 125px;
      float: right;
    }
  }
}

.invite-url {
  overflow-wrap: break-word;
}

.lg-btn {
  margin-top: 10px !important;
  background-color: var(--color-main) !important;
  font-weight: 800;
  color: white !important;
}

.inverted-card {
  background-color: var(--color-main) !important;
  color: white !important;
}

.lg-sign-up-btn {
  background-color: var(--color-main) !important;
  color: white !important;
  font-weight: 600;
}

.lg-btn:hover {
  background-color: var(--color-lg-dark) !important;
}

.lg-link {
  color: var(--color-lg) !important;
  &:hover {
    text-decoration: underline;
  }
}
