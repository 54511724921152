div.DraftEditor-root {
  border: 1px solid #e2e2e2;
  background-color: #ffffff;
  height: 200px;
  width: 100%;
  overflow-y: auto;
  margin-bottom: 25px;
  padding: 10px;
}
div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  height: 100%;
}
div.toolbar {
  position: relative;
  width: 100%;
  background-color: #b09cec;
  padding: 10px 20px;
  margin: auto;
  text-align: left;
}
div.toolbar button {
  border: 1px solid black;
  padding:5px;
  margin-right:4px;
  background-color:black;
  color:white;
}